import React from "react";

function HomeTest() {
    return (
        <>
       <div class="bg-white relative isolate overflow-hidden dark:bg-gray-900 px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
  <div class="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
    <div class="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
      <div class="lg:pr-4">
        <div class="lg:max-w-lg">
          <h1 class="mt-2 text-3xl font-bold tracking-tight dark:text-white sm:text-4xl">Características</h1>
          <p class="mt-6 text-xl leading-8 dark:text-white">Contamos con dos áreas de investigación, ciencia de datos y desarrollo de blockchain, las cuales trabajan de forma independiente pero que también pueden combinarse según las necesidades de nuestros clientes.</p>
        </div>
      </div>
    </div>
    {/* <div class="-ml-12 -mt-12 p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
      <img class="w-[48rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]" src="https://tailwindui.com/img/component-images/dark-project-app-screenshot.png" alt="">
    </div> */}
    <div class="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
      <div class="lg:pr-4">
        <div class="max-w-xl text-base leading-7 dark:text-white lg:max-w-lg">
        <p class="text-base font-semibold leading-7 text-indigo-600">Ciencia de datos</p>
          <p>Nuestra área de investigación de ciencia de datos proporciona soluciones a problemas empresariales mediante el análisis de grandes cantidades de datos. Ofrecemos análisis de negocio, informes y visualización de datos, análisis predictivo y minería de datos, aprendizaje automático y aprendizaje profundo e investigación de datos. Todos estos servicios nos permiten ofrecer resultados precisos y personalizados a cada uno de nuestros clientes.</p>
        </div>
        <div class="max-w-xl mt-4 text-base leading-7 dark:text-white lg:max-w-lg">
        <p class="text-base font-semibold leading-7 text-indigo-600">Blockchain</p>
          <p>En SYP TECH también tenemos un área de investigación en desarrollo blockchain, en la que ofrecemos servicios de desarrollo de smart contracts y soluciones basadas en tecnología blockchain. Dentro de esta área, nuestras principales fortalezas están en la investigación y desarrollo de smart contracts en solidity y otros lenguajes de contratos inteligentes basados en Rust, como Sway, dentro del ecosistema Fuel. Utilizamos esta tecnología para garantizar la seguridad y transparencia de nuestras soluciones, lo que nos permite ser líderes en el sector de la ciencia de datos y el desarrollo de blockchain.
</p>
        </div>

        <div class="max-w-xl mt-4 text-base leading-7 dark:text-white lg:max-w-lg">
          <p>Además, en SYP TECH contamos con profesionales altamente cualificados para la formación en ciencia de datos tanto para perfiles cuantitativos como para aquellos que no han estado involucrados con estos temas pero que, apalancándose en habilidades de este tipo, pueden optimizar sus tareas diarias en la empresa y mejorar su desempeño profesional.</p>
        </div>
        <div class="max-w-xl mt-4 text-base leading-7 dark:text-white lg:max-w-lg">
          <p>Si estás buscando soluciones innovadoras y eficientes para mejorar la competitividad de tu empresa a través de la ciencia de datos o la tecnología blockchain, no dudes en ponerte en contacto con nosotros. Estaremos encantados de ayudarle a encontrar la solución perfecta para sus necesidades empresariales.</p>
        </div>
      </div>
    </div>
  </div>
</div>


        </>
    )
}

export default HomeTest;