import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import HomeTest from './components/Hometest';

function App() {
  return (
    <>
    <Navbar />
    
    <Home />

    <HomeTest />
    
    <Footer />
    </>     
  );
}

export default App;